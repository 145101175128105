import * as React from "react";
import Layout from "../components/layout.js";
import PageContainer from "../components/pageContainer.js";
import styled from "styled-components";
import { useLocalization } from "gatsby-theme-i18n";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { BsChevronLeft } from "react-icons/bs";
import { when } from "../styles/theme.js";

const translations = {
  en: {
    pageNotFound: "Page Not Found",
    back: "Go Back",
  },
  zh: {
    pageNotFound: "找不到網頁",
    back: "返回主頁",
  },
};

const NotFoundSection = styled.section`
  padding-top: 20rem;
  padding-bottom: 20rem;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.darkBlue};
`;

const ContentContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.colors.white};
`;

const Content = styled.div`
  position: relative;
  padding: 5rem 0;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 8rem;
  line-height: 9.8rem;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  ${when("md")} {
    font-size: 20rem;
    line-height: 24rem;
  }
`;

const Description = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.25;
  text-align: center;
  text-transform: uppercase;

  ${when("md")} {
    font-size: 4rem;
    line-height: 5rem;
  }
`;

const Circle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: -10rem;
  background-color: ${(props) => props.theme.colors.darkBlue};
  border-radius: 9999rem;
  z-index: 0;

  ${when("md")} {
    right: auto;
    left: 35%;
  }
`;

const CircleWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled.div`
  position: relative;
`;

const GoBack = styled(Link)`
  margin-top: 7.8rem;

  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  margin-right: 0.5rem;
`;

// markup
const NotFoundPage = ({ data, pageContext: { originalPath }, uri }) => {
  const { locale } = useLocalization();
  const pageNode = data?.page?.nodes[0];
  if (!pageNode) {
    return null;
  }
  const translation = translations[locale];

  return (
    <Layout
      title={translation.pageNotFound}
      description={translation.pageNotFound}
      uri={uri}
      image={pageNode.image?.localFile.childImageSharp.original.src}
      imageAlt={pageNode.image?.description}
      mainNav={data.mainNav?.nodes[0]}
      globalNav={data.globalNav?.nodes[0]}
      brands={data.brands.nodes[0].brands}
      footerNav={{
        primary: data.primaryFooterNav?.nodes[0],
        secondary: data.secondaryFooterNav?.nodes[0],
      }}
      originalPath={originalPath}
      settings={data.settings?.nodes[0]}
      hasDeco={false}
    >
      <NotFoundSection as="section">
        <ContentContainer>
          <ContentWrapper>
            <Circle>
              <CircleWrapper>
                <StaticImage
                  src="../images/background_pattern.png"
                  alt=""
                  width={400}
                  height={400}
                  placeholder={"none"}
                />
              </CircleWrapper>
            </Circle>
            <Content>
              <Title>404</Title>
              <Description>{translation["pageNotFound"]}</Description>
            </Content>
          </ContentWrapper>
          <GoBack as="a" href="/">
            <Icon as={BsChevronLeft} />
            {translation.back}
          </GoBack>
        </ContentContainer>
      </NotFoundSection>
    </Layout>
  );
};

export const data = graphql`
  query NotFoundPageQuery($hrefLang: String!) {
    globalNav: allContentfulNavigation(
      filter: { contentfulid: { eq: "global" }, node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...NavLinks
      }
    }
    mainNav: allContentfulNavigation(
      filter: { contentfulid: { eq: "main" }, node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...NavLinks
      }
    }
    brands: allContentfulPageSectionBrands(
      filter: {
        node_locale: { eq: $hrefLang }
        contentful_id: { eq: "2kjRmkm5mviLHU4RYXdrP6" }
      }
    ) {
      nodes {
        brands {
          name
          slug
        }
      }
    }
    primaryFooterNav: allContentfulNavigation(
      filter: {
        contentfulid: { eq: "footer-primary" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...NavLinks
      }
    }
    secondaryFooterNav: allContentfulNavigation(
      filter: {
        contentfulid: { eq: "footer-secondary" }
        node_locale: { eq: $hrefLang }
      }
    ) {
      nodes {
        ...NavLinks
      }
    }
    settings: allContentfulSiteSettings(
      filter: { node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...SiteSettings
      }
    }
    page: allContentfulPage(
      filter: { contentfulid: { eq: "home" }, node_locale: { eq: $hrefLang } }
    ) {
      nodes {
        ...Page
      }
    }
  }
`;

export default NotFoundPage;
